.heatmap-key-container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.heatmap-key-title {
  font-size: 18px;
  align-items:flex-start;
  margin-bottom: 10px;
  text-align: center;
}

.heatmap-key {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 4pt;
}

.heatmap-key-color {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  text-align: center;
}

.heatmap-key-label {
  font-size: 14px;
}
