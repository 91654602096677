.mahut-demo-tab-bar {
  display: flex;
  flex-direction: row;
}

.mahut-demo-tab-bar .page-tab {
  flex-grow: 1;
  flex-basis: 0;
  padding: 5pt;
  border: 1px solid;
  border-top-left-radius: 8pt;
  border-top-right-radius: 8pt;
  border-bottom-style: solid;
  margin-top: 10pt;
}

.mahut-demo-tab-bar .page-tab.page-tab-selected {
  margin-top: 0;
  border-bottom-style: none;
}


@media print {
  .mahut-demo-tab-bar {
    display: none;
  }
}