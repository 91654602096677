.current-indicator {
  display: inline-block;
  border: 2px solid lightgray;
  border-radius: 15pt;
  padding: 2pt 3pt;
  font-size: 18pt;
}
.current-indicator.buy {
  border-color: green;
  color: green;
}
.current-indicator.sell {
  border-color: red;
  color: red;
}
.current-indicator>span {
  position: relative;
  top: 2pt
}
