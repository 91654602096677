.mahut-demo-descr {
  display: flex;
  flex-direction: row;
}

.mahut-demo-descr.no-issue {
  background-color: lightgray;
  color: white;
}

.descr-controls {
  display: flex;
  flex-direction: column;
  border-right: 1px solid;
}

.descr-controls-button {
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  vertical-align: middle;
  padding: 8pt;
  width: 6em;
  cursor: pointer;
}

.descr-controls-button.strategy-unselected {
  background-color: #ddf;
}

.descr-controls-button.strategy-selected {
  background-color: #bbf;
}

.mahut-demo-descr.no-issue .descr-controls-button.strategy-unselected {
  background-color: inherit;
  border-bottom: 1px solid;
}

.mahut-demo-descr.no-issue .descr-controls-button.strategy-selected {
  background-color: darkgray;
  border-bottom: 1px solid;
}

.descr-controls-button .strategy-mark {
  display: none;
  position: absolute;
  height: 100%;
  width: 12pt;
  top: 0;
  left: 100%;
  overflow: hidden;
}

.descr-controls-button .strategy-mark>div {
  background-color: #bbf;
  border-top: 1px solid;
  border-right: 1px solid;
  position: absolute;
  width: 8pt;
  height: 8pt;
  transform: rotate(45deg);
  left: -4pt;
  top: calc(50% - 4pt);
}

.mahut-demo-descr.no-issue .descr-controls-button .strategy-mark>div {
  background-color: darkgray;
}

.descr-controls-button.strategy-selected .strategy-mark {
  display: block;
}

.descr-description {
  position: relative;
  flex-grow: 1;
  padding: 8pt 14pt;
}

.descr-select-period {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
}

.descr-select-period>div:first-child {
  flex-grow: 2;
  flex-basis: 0;
}

.descr-select-period>div:last-child {
  position: relative;
  overflow: hidden;
  flex-grow: 1;
  flex-basis: 0;
}

.descr-select-period>div:last-child>div {
  position: relative;
  left: -100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  transition: left 1s;
}

.descr-select-period.select-showing>div:last-child>div {
  left: 0;
}

.descr-period-button {
  flex-basis: 6em;
  border-top: 1px solid;
  border-right: 1px solid;
  text-align: center;
  vertical-align: middle;
  padding: 8pt;
  cursor: pointer;
}

/*
.descr-period-button:last-child {
  border-right: none;
}
*/



@media print {
  .mahut-demo-descr {
    display: none;
  }
}