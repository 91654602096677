.mahut-demo-detail {
  position: absolute;
  inset: 0;
  padding: 8pt;
  display: flex;
  flex-direction: column;
}
.mahut-demo-detail.no-issue {
  background-color: lightgray;
  color: white;
}
.mahut-demo-detail .detail-name {
  font-size: 18pt;
  text-align: center;
  margin-bottom: 8pt;
}
.mahut-demo-detail .detail-contents {
  position: relative;
  flex-grow: 1;
  overflow-y: auto;
}