.mahut-demo-head {
  display: flex;
  flex-direction: row;
  align-items: stretch;
}

.mahut-demo-head>div:first-child {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.mahut-logo {
  height: 10pt;
}

.mahut-logo img {
  height: 120%;
}

.mahut-demo-cont {
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  gap: 12pt;
  padding: 12pt;
}

.mahut-demo-cont .cont-main {
  flex-grow: 4;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  gap: 12pt;
}

.mahut-demo-cont .cont-graph {
  position: relative;
  flex-grow: 1;
  border: 1px solid;
}

.mahut-demo-cont .cont-descrip {
  position: relative;
  border: 1px solid;

}

.mahut-demo-cont .cont-detail {
  position: relative;
  flex-grow: 1;
  flex-basis: 0;
  min-width: 200pt;
  border: solid 1px;
}

.mahut-demo-foot {
  display: flex;
  flex-direction: row;
  padding: 8pt;
  background-color: rgb(238, 238, 238);
  font-size: 70%;
}

.mahut-demo-foot .foot-right {
  flex-grow: 1;
  text-align: right;
}


@media print {

  .mahut-demo-head .mahut-demo-detail .mahut-demo-cont,
  button,
  h3,
  .mahut-demo-detail,
  .detail-name,
  .detail-contents,
  .detailsReport .mahut-demo-cont .mahut-lookup .cont-descrip .mahut-demo-foot {
    display: hide;
  }

  .mahut-demo-cont {

    flex-grow: 1;
    display: flex;
    flex-direction: row;
    gap: 12pt;
    padding: 12pt;
    /* TODO Me */
  }


  .mahut-demo-cont .cont-graph {
    border: 0px;
  }

  .cont-main {
    border: none;
  }

  .random-div {
    display: none
  }


  .mahut-demo-cont .cont-descrip {
    position: relative;
    border: none;

  }

  .mahut-demo-cont .cont-detail {
    position: relative;

    border: none;
  }
}