.market-perf-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8pt;
}

.market-perf-details .mpd-main-stats {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 4pt;
}

.market-perf-details .mpd-main-breakdown {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  gap: 4pt;
}

.market-perf-details .mpd-main-breakdown span {
  text-align: center;
}

.market-perf-details .mpd-label {
  text-align: right;
}

.market-perf-details .mpd-value {
  text-align: right;
}

.market-perf-details .mdp-title {
  text-decoration: underline;
}