.capital-exposure-details {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.capital-exposure-details > .ced-contents {
  display: grid;
  grid-template-columns: max-content max-content;
}
.capital-exposure-details > .ced-contents > .ced-contents-label {
  text-align: right;
  padding-right: 4pt;
}
.capital-exposure-details > .ced-contents > .ced-contents-value {
  text-align: right;
}
.capital-exposure-details > .ced-summary {
  font-size: 133%;
}
