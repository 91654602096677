.printableDocument {
    font-family: 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    color: #252525;
    line-height: 1.6;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    max-width: 100%;
    position: relative;

    /* Scrolls within web view, override in print media */
    position: absolute;
    inset: 0;
    overflow-y: auto;
}



/* Header styles */
.headerImage {
    max-width: 100%;
    height: auto;
    margin-bottom: 10px;
}


/* Each section contains one report */
.printable-section {
    width: 100%;
}

/* Section header and footer styling */
.sectionHeader,
.sectionFooter {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #FFFFFF;
    padding: 10px;
    margin-top: 10px;
}

.sectionHeader h3,
.sectionFooter p {
    margin: 0;
}

.sectionHeader .dateDisplay,
.sectionFooter .dateDisplay {
    font-size: 12pt;
}

/* Button styling */
button {
    background-color: rgba(255, 166, 0, 0.612);
    color: white;
    border: none;
    padding: 10px 20px;
    text-transform: uppercase;
    margin-top: 20px;
    cursor: pointer;
    border-radius: 5px;
    position: relative;
    z-index: 10;
}

button:hover {
    background-color: orange;
}

/* Chart and details container styling */
.chartReport,
.detailsReport {
    display: flex;
    flex-direction: column;
    background-color: #ffffff;
    padding: 15px;
    margin-bottom: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    min-height: 400px;

}

.chartReport {
    flex-grow: 1;
    background-color: #FFFFFF;
/*    margin-right: 20px; */
}

.detailsReport {
    width: 63mm;
    position: relative;
}

/* Flex container for each section */
.RPsection,
.CPsection,
.MPsection,
.CEsection,
.CGsection,
.MSsection {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 40px;
    /* Increased gap for more space between chart and details */
    margin-bottom: 20px;
    /* Additional space between sections */
}

.mahut-demo-detail .mahut-demo-cont {
    display: none;
}

.printableDocument .reportHeader {
    text-align: center;
}

.reportHeader {
    justify-content: space-between;
}

.reportHeader button {
    margin-bottom: 20px;
}

.headerImage {
    width: 100%;
    height: auto;
}

.headerContent .dateDisplay {
    padding-left: 80px;
    font-size: 16px;
}

.headerImage .dateDisplay {
    color: transparent;
}

.welcomeReport {
    color: grey;
    justify-content: center;
}

@media print {

    .mahut-demo-cont {
        display: hide;
    }

    .cont-main {
        display: none;
    }

    .issue-home {
        display: hide;
    }

    .mahut-demo-cont {
        display: hide;
    }

    .printableDocument {
        min-height: 297mm; /* A4 height */
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        width: 100%;
        height: 100%;
        border: none;

        /* Permits long multipage document */
        position: relative;
        inset: initial;
        overflow: initial;
    }

    .CPsection,
    .CEsection,
    .MPsection,
    .RPsection,
    .CGsection,
    .MSsection {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        padding: 10mm 0;
    }

    .chartReport {
        width: 160mm;
    }

    .detailsReport .chartReport,
    .detailsReport {
/*        flex: 1; */
        box-shadow: none;
        border: none;
        padding: 0;
        margin: 0;
    }

    .mahut-demo-detail {
        display: flex;
        flex-direction: column;
        background: #FFFFFF;
        padding: 8pt;
    }

    .mahut-demo-detail .detail-name,
    .mahut-demo-detail .detail-contents {
        color: #000000;
    }



    .descriptionReport {
        border: 2px solid #eaeaead7;
        position: absolute;
    }

    .mahut-demo-cont .cont-detail {
        position: absolute;
    }

    .mahut-demo-foot {
        color: transparent;
    }

    .printableDocument .reportHeader {
        text-align: center;
    }

    .reportHeader {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        color: #FFFFFF;
        border-bottom: 5px solid orange;
        margin-top: 30px;
    }

    .reportHeader h3 {
        margin: 0;
        font-size: 24pt;
    }

    .reportHeader button {
        background-color: orange;
        color: #000000;
        padding: 10px 20px;
        border: none;
        cursor: pointer;
        font-weight: bold;
        font-size: 14pt;
        text-align: center;
    }

    .reportHeader .dateDisplay {
        color: #9b9b9b;
        font-size: 12pt;
        margin-left: 20px;
    }

    .tickerSymbol {
        display: none;
    }

    .reportHeader button {
        margin-bottom: 20px;
    }

    button {
        display: none;
    }

    .headerImage {
        width: 100%;
        height: auto;
    }

    .tickerSymbol {
        display: none;
    }

    .dateDisplay {
        color: grey;
        position: right;
    }

    button {
        font-size: large;
        text-align: center;
    }

    .welcomeReport {
        text-align: center;
    }

    .mahut-demo-detail .detail-name {
        display: none;
    }

    .footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        text-align: center;
        border-top: 2px solid rgb(183, 183, 183);
        background: #fff;
    }

    .footer .pageNumber:after {
        content: counter(page);
        page-break-after: always;
    }

    .sectionHeader {
        justify-content: space-between;
        align-items: center;
        width: 100% !;
        border-bottom: 2px solid rgb(183, 183, 183);
        page-break-before: always;
    }

    .sectionHeader h3 {
        margin: 0;
    }

    .sectionHeader .dateDisplay {
        margin: 0;
        font-size: 14px;
    }

    .mahut-demo-detail {
        padding-top: 10px;
    }

    .cont-descrip {
        border: none;
    }


    .cont-graph {
        border: none;
    }

    .MSsection {
        -webkit-print-color-adjust: exact;
        /* For WebKit browsers */
        color-adjust: exact;
        /* For Firefox */
    }
}