.mahut-option-picker {
  --options-button-height: 26pt;
  --options-button-width: 100pt;
  --options-button-color: #ddf;
  display: flex;
  flex-direction: row-reverse;
  border: 1px solid;
  width: calc(var(--options-button-width) * 2);
  box-sizing: border-box;
  z-index: 1000;
}

.mahut-option-picker .options-menu {
  position: absolute;
  top: 0;
  right: -1px;
  left: -1px;
  background-color: white;
  border: 1px solid;
  border-top: none;
}

.mahut-option-picker .option-button {
  position: relative;
  flex: 1 1 0;
  cursor: default;
  display: grid;
  align-items: center;
  justify-items: center;
  border-bottom: 1px solid;
  height: var(--options-button-height);
  box-sizing: border-box;
}

.mahut-option-picker .option-button.option-check {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 2pt;
  padding-left: 4pt;
}

.mahut-option-picker .option-button:last-child {
  margin-bottom: -1px;
}

.mahut-option-picker .option-button:hover,
.mahut-option-picker .option-button.option-active,
.mahut-option-picker .option-button.option-selected {
  background-color: var(--options-button-color);
}

.mahut-option-picker .option-button .strategy-mark {
  display: none;
  position: absolute;
  height: 100%;
  width: 12pt;
  top: 0;
  left: 100%;
  overflow: hidden;
}

.mahut-option-picker .option-button .strategy-mark>div {
  background-color: var(--options-button-color);
  border-top: 1px solid;
  border-right: 1px solid;
  position: absolute;
  width: 8pt;
  height: 8pt;
  transform: rotate(45deg);
  left: -4pt;
  top: calc(50% - 4pt);
}

.mahut-option-picker .option-button.option-category.option-active .strategy-mark {
  display: block;
}

.mahut-option-picker .current-options {
  flex: 1 1 0;
  border-right: 1px solid;
}

.mahut-option-picker .menu-host {
  flex: 1 1 0;
  position: relative;
}

.mahut-option-picker .menu-host .logo-title {
  position: absolute;
  inset: 8pt;
  display: flex;
  flex-direction: column;
  gap: 8pt;
}

.mahut-option-picker .menu-host .menu-logo {
  flex: 3 1 0;
  background-image: url(/public/drivers-logo-for-light-bkg.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.mahut-option-picker .menu-host .menu-title {
  flex: 1 1 0;
  background-image: url(/public/drivers-wave-for-light-bkg.png);
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}



@media print {
  .mahut-option-picker {
    display: none;
  }
}