.mahut-lookup {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3pt;
}

.mahut-lookup input[type=text] {
  border-radius: 10pt;
  padding: 3pt 6pt;
  width: calc(100% - 15pt);
}

.mahut-lookup .material-icons {
  vertical-align: bottom;
  cursor: pointer;
}

.mahut-lookup .material-icons .lookup-disabled {
  pointer-events: none;
  cursor: not-allowed;
  opacity: 50%;
}

.mahut-lookup .mahut-lookup-popup {
  flex-grow: 1;
  display: inline;
  position: relative;
}

.mahut-lookup .mahut-lookup-popup .mahut-lookup-list {
  position: absolute;
  top: calc(100% + 5pt);
  left: 0;
  right: auto;
  width: auto;
  max-height: 50vh;
  overflow-y: auto;
  display: grid;
  grid-template-columns: min-content min-content;
  column-gap: 5pt;
  white-space: nowrap;
  background-color: rgba(211, 206, 206);
  padding: 10px;
  border-radius: 3pt;
  z-index: 1;
}

.mahut-lookup .mahut-lookup-list span {
  margin-bottom: 5px;
  white-space: nowrap;
  cursor: pointer;
}



@media print {
  .mahut-lookup {
    display: none;
  }

}