.risk-perf-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8pt;
}

.risk-perf-details .rpd-main-stats {
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 4pt;
}

.risk-perf-details .rpd-main-breakdown {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  gap: 4pt;
}

.risk-perf-details .rpd-main-breakdown span {
  text-align: center;
}

.risk-perf-details .rpd-label {
  text-align: right;
}

.risk-perf-details .rpd-value {
  text-align: right;
}

.risk-perf-details .rpd-title {
  text-decoration: underline;
}
