.capital-growth-details {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  gap: 5pt;
  text-align: center;

}

.capital-growth-details .report-section {
  position: relative;
  min-height: 30pt;
  overflow-y: auto;
}

.capital-growth-details .report-contents {
  display: grid;
  grid-template-columns: max-content 1fr;
  column-gap: 4pt;
  text-align: center;
  align-items: center;
  margin-left: 30px;
}

.capital-growth-details .report-col-heading {
  position: sticky;
  top: 0;
  text-decoration: underline;
  background-color: white;
}

.capital-growth-details .report-grid {
  display: grid;
  grid-template-columns: 1fl 1fl;
  gap: 8pt;
  text-align: center;
}

.capital-growth-details .report-grid div {
  text-align: center;
}

.capital-growth-details .report-grid div.report-heading {
  text-align: center;
  grid-column: span 2;
}